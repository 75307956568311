
<!-- 推荐 -->

<template>
    <div :class="$style.box" :style="{'right':30+'px' }" class="kefu">
      <div class="w-128 p-0-15 bg-f rad-2 m-b-20" :class="$style.boxshadow" >
		<h1 class="f-14  h-55 l-h-55  tc b-b-e5 --color flex-r" ><i class="iconfont b2b-lianxikefu classicon f-24 "></i> <span class="relative t--4">联系客服</span></h1>
		<h1 class="f-14  h-55 l-h-55  tc --color flex-r" ><i class="iconfont b2b-dianhua b2b-dianhua classicon f-24 "></i> <span class="relative t--4">电话热线</span></h1>
		<div class="b-b-e5 tc f-14 bold  p-b-15 m-b-15 	c-3 ">
			<p class="m-b-10 nowrap bold ">{{aftersalephone}}</p>
			<p class="nowrap bold ">{{phone}}</p>
		</div>
      	<div class="tc" v-if="$store.state.app.gettenant.b2bwshoppingqrcode">
			<img class="w-95 h-95" :src="$store.state.app.gettenant.b2bwshoppingqrcode" alt="">
			<p class="h-40 l-h-30 p-b-10">小程序商城</p>
		</div>
		<div class="tc" v-else >
			<img class="w-95 h-95" :src="$store.state.app.qrcodeurl" alt="">
			<p class="h-40 l-h-30 p-b-10">关注公众号</p>
		</div>
      </div>
	  <div class="h-50 l-h-50 tc bg-f cursor flex s-c --color" id="return_top" @click="Backtops" :class="$style.boxshadow">
		 <i class="iconfont b2b-fanhuidingbu classicon f-24"></i> 返回顶部
	  </div>
    </div>
</template>

<script>
export default {
    name: 'recommend',
	data() {
		return{
			aftersalephone:sessionStorage.getItem('aftersalephone'),
			phone:sessionStorage.getItem('phone')
		}
	},
    props: {
        formData: {
            type: Object,
            default: () => ({})
        }
    },
	watch:{
		formData:function(e){
			this.formData=e
			console.log('formData',e)
		},
		
		
	},
	computed:{
		clientWidth(){
			let Width
			if(location.href.indexOf('edit')==-1){
				Width=((document.documentElement.clientWidth-1200)/2)+60+1200
				// console.log('Width',Width)
			}else{
				var dom = document.querySelector('.Editor_dragArea_1_sDW');
				// console.log('dom',dom)
				if(dom){
					
					Width=((dom.offsetWidth-1200)/2)+1200+20
					// console.log('Width',dom)
					// console.log('dom',dom.offsetWidth);
				}
			}
		
			
			return Width
		},
	},
	mounted() {
		this.Backtop()
	},
	methods: {
		
	
		Backtops(){
			this.Backtop()
		}
	},
};
</script>

<style>
	/* .ViewComponentWrap_active_1ORx_, .viewBox .active:after */
	@media screen and (max-width: 1350px){
		.kefu{display: none;}
	}
	.DIN{font-family: D-DIN DIN;}
	 /* .customerservice_box_2SgjQ{width: 140px !important;} */
</style>
<style module>
   .box{
		position: fixed;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 130px ;
		height: 385px;
		z-index: 10;
   }
   .boxshadow{box-shadow: 0px 0px 8px rgba(51,51,51,0.15);}
  
</style>
